import React from "react"

import Layout from '../components/Layout'
import Main from '../components/Main'
import SEOComponent from '../components/SEOComponent'

export default function Home() {
  return (
    <>
      <SEOComponent title={`Matt Oliver - Austin, TX`}></SEOComponent>
      <Layout>
      	<Main />
      </Layout>
    </>
  )
}