import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import randomcolor from "randomcolor"

const StyledMain = styled.main`
  padding-left: 1.33em;
  @media (max-width: 375px) {
    padding-left: .67em;
  }
`;

const StyledLink = styled(props => <Link {...props} />)`  
  font-size: 11rem;
  line-height: 1em;
  text-decoration: none;
  &:hover {
    color: ${randomcolor()}
  }

  @media (max-width:479px) and (min-width:280px) {
    font-size: 4em;
  }​
  @media only screen and (max-device-width: 480px) {
    font-size: 5em;
  }
  @media only screen and (device-width: 768px) {
     font-size: 6.5em;  
  }
`;

const StyledLinkTwo = styled(props => <Link {...props} />)`  
  font-size: 11rem;
  line-height: 1em;
  text-decoration: none;
  &:hover {
    color: ${randomcolor()}
  }
  @media (max-width:479px) and (min-width:280px) {
    font-size: 4em;
  }​
  @media only screen and (max-device-width: 480px) {
    font-size: 5em;
  }
  @media only screen and (device-width: 768px) {
     font-size: 6.5em;  
  }
`;

const StyledLinkThree = styled(props => <Link {...props} />)`  
  font-size: 11rem;
  line-height: 1em;
  text-decoration: none;
  &:hover {
    color: ${randomcolor()}
  }
  @media (max-width:479px) and (min-width:280px) {
    font-size: 4em;
  }​
  @media only screen and (max-device-width: 480px) {
    font-size: 5em;
  }
  @media only screen and (device-width: 768px) {
     font-size: 6.5em;  
  }
`;

const StyledSpan = styled.span`
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.1em;
  margin-top: 46px;
  padding: 0px 6px;
  @media (max-width: 375px) {
    font-size: 3em;
  }
`;


export default function Main() {
  return (
    <StyledMain>
      <div className="hero">
        <hr className="nav-rule" />
        <div>
          <p className="index-links">
            <StyledLink
              to="/"
              className="index-link-styling"
            >
              Musician
            </StyledLink>
            <StyledSpan>, </StyledSpan>
            <StyledLinkTwo
              to="/"
              className="index-link-styling"
            >
              Artist
            </StyledLinkTwo>
            <StyledSpan>, </StyledSpan>
            <StyledLinkThree
              to="/"
              className="index-link-styling"
            >
              Engineer
            </StyledLinkThree>
            <StyledSpan>.</StyledSpan>
          </p>

          <p className="index-links">
            <StyledSpan>Austin, Texas.</StyledSpan>
          </p>
        </div>
      </div>
    </StyledMain>
  )

}

